.d-flex {
     display: flex !important;
}

a {
     text-decoration: none !important;
}

.body-locked {
     overflow: hidden;
}

.text-grey {
     color: gray !important;
}

.left-section {
     height: 100vh;
     position: absolute;
     top: 0;
     left: 0;
     width: 5%;
}

.sidebar {
     position: fixed;
     z-index: 2;
     background-color: white;
     transition: all 0.5s ease;
     box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
     width: 5.7%;
}

.sidebarShow {
     width: 220px;
}

/* .sidebarChild {
     height: 100vh;
} */

.nav-list li {
     width: 100%;
}

.nav-list li a {
     display: flex;
     align-items: center;
     gap: 6px;
     color: var(--primaryColor);
     padding: 10px;
     width: 100%;
     border-radius: 8px;
     transition: all 0.5s;
     cursor: pointer !important;
     /* justify-content: center; */
}

.activeMenu {
     background-color: var(--primaryColor);
     color: white !important;
     box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.3);
}

.nav-list li a:hover {
     background-color: var(--primaryColor);
     color: white;
}

.nav-list li a svg {
     font-size: 22px;
}

.popup {
     display: block;
     position: absolute;
     left: 100%;
     top: 50%;
     transform: translateX(-50%);
     padding: 5px;
     background-color: rgba(0, 0, 0, 0.7);
     color: #fff;
     border-radius: 3px;
     white-space: nowrap;
}

.right-section {
     position: absolute;
     top: 0;
     right: 0;
     width: 94.3%;
     background: url("./image/loginBackground.jpg");
     background-repeat: no-repeat;
     background-size: cover;
}

.navigatinBar {
     background-color: white;
}

.ratingComponent {
     display: flex;
     align-items: center;
     gap: 3px;
}

.ratingComponent p {
     margin: 0;
}

.ratingComponent svg {
     font-size: 20px !important;
     color: #000000 !important;
}

.storeName {
     width: 330px;
     overflow-x: hidden;
}

.catagoryList {
     margin: 0 0 0 16px;
}

.catagoryList ul {
     display: flex;
     align-items: center;
     overflow-x: auto;
     white-space: nowrap;
     padding-right: 8px;
     padding-bottom: 5px;
     /* justify-content: center; */
}

.catagoryList ul::-webkit-scrollbar {
     display: none;
}

.catagoryList ul li {
     border-radius: 10px;
     background-color: #FFFFFF;
     box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
     padding: 10px 20px;
}

/* li .navActive {
     border: 2px solid red !important;
} */
li.roboto-medium-18.c-pointer.navActive {
     background-color: var(--primaryColor);
     color: white;
     border-radius: 10px;
     /* position: absolute;
     top: 0;
     left: -9px;
     /* border-left: 9px solid #F6B746;
     border-left: 9px solid var(--buttonColor); */
     /* border: 2px solid red; */
}

.searchBar input {
     padding: 10px 34px;
     box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
     margin-left: 16px;
}

.searchIcon {
     position: absolute;
     top: 22px;
     left: 23px;
     z-index: 1;
}

.searchIcon svg {
     font-size: 20px;
     color: #8f8f8f;
}

.mainScreen {
     display: flex;
     width: 100%;
}

.text-primary {
     color: var(--primaryColor) !important;
}

.hamBurger {
     display: inline;
}

.hamBurger p {
     border-radius: 50% !important;
     width: 30px;
     height: 30px;
     border: 2px solid var(--primaryColor) !important;
     cursor: pointer;
     /* box-shadow: 0px 2px 7px -3px #000000; */
}

.cartBar,
.closeBtn {
     display: none;
}

.navList::-webkit-scrollbar {
     display: none;
}

.productShow {
     display: flex;
     flex-direction: column;
     height: 99%;
     width: 72%;
     position: absolute;
     top: 0;
     left: 0;
     /* background-color: #F2F1F2; */
}

.cartPage {
     width: 27%;
     /* height: 90vh; */
     position: absolute;
     top: 0;
     right: 0;
     height: 98%;
}

/* .homeCart {
     width: 25%;
     position: relative;
}

.cart {
     position: absolute;
     top: 0;
     right: 0;
} */

.cardMain {
     width: 100%;
     border-radius: 14px;
     border: none;
     cursor: pointer;
     /* height: 100%; */
     box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.scroll-item {
     flex: 1;
     overflow-y: auto;
     margin-right: 2px;
}

.scroll-item::-webkit-scrollbar {
     width: 6px;
}

.scroll-item::-webkit-scrollbar-track {
     border-width: 1px;
     border-color: #EDEDED;
}

.scroll-item::-webkit-scrollbar-thumb {
     width: 9px;
     height: 35%;
     /* background-color: #C7C7C7; */
     background-color: var(--primaryColor);
     border-radius: 5px;
}

.empCart {
     position: relative;
     width: 100px;
     height: 100px;
     background-color: #E8E7E8;
     /* border-radius: 50%; */
}

.empCart>img {
     position: absolute;
     top: 50%;
     left: 50%;
     width: 52px;
     height: 53px;
     transform: translate(-50%, -50%);
     color: white;
}

.cartProductList::-webkit-scrollbar {
     display: none;
}

.cartItem {
     /* width: 100%; */
     height: 69px;
     /* border: 1.5px solid var(--primaryColor) !important; */
     /* background-color: #F2F1F2 !important; */
     box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
     margin: 0 2px;
}

.cardItem>h4 {
     /* width: 100%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis; */
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
     width: 100%;
     height: 45px;
     line-height: 22.5px;
}

.productList::-webkit-scrollbar {
     display: none;
}

.addressText {
     width: 377px;
     white-space: nowrap;
     overflow: hidden;
}

.phoneText {
     width: 170px;
     white-space: nowrap;
     overflow: hidden;
     color: #1f1f1f;
}

.billSection ul {
     list-style: none;
}

.billSection li {
     display: flex;
     justify-content: space-between;
     align-items: center;
}

.homeCart {
     width: 25%;
}

.txtArea {
     border: 2px solid #E8E7E8;
     border-radius: 14px;
     padding: 8px;
     overflow: hidden;
}

select::-ms-expand {
     display: none !important;
     margin-right: 3px;
}

.billSection {
     overflow-y: scroll;
     height: 53vh;
     background-color: #f5f5f5;
     border-radius: 10px;
     margin: 20px 0 20px 0;
}

.billSection::-webkit-scrollbar {
     width: 9px;
}

.billSection::-webkit-scrollbar-track {
     border-width: 1px;
     border-color: #EDEDED;
}

.billSection::-webkit-scrollbar-thumb {
     width: 9px;
     height: 52%;
     background-color: var(--primaryColor);
     border-radius: 5px;
}

.tipButton>button {
     width: 75px;
     height: 36px;
     box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
     border: none;
}

.activeTipButton {
     background-color: var(--primaryColor) !important;
     color: white !important;
}

.tipButton>input {
     width: 78px;
     height: 38px;
     box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
     border: none;
     border-radius: 4px;
     padding: 4px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     margin: 0;
}

.onlineUser {
     position: absolute;
     right: -13px;
     bottom: -13px;
     color: lightgreen;
}

.profileModal {
     position: absolute;
     top: 36px;
     right: 8px;
     width: 160px;
     background-color: var(--primaryColor);
     z-index: 2;
     border-radius: 10px;
     padding: 10px;
}

.profileModalListUl>li {
     padding: 1px 2px;
     font-family: "Roboto", sans-serif !important;
     font-weight: 500 !important;
     font-style: normal !important;
     font-size: 15px !important;
     color: #fff;
     border-bottom: 2px solid #fff;
     cursor: pointer !important;
}

.profileModalListUl>*:last-child {
     border-bottom: none;
     margin: 0;
}

.px-2.flex-grow-1 {
     height: 80px;
     overflow-y: hidden;
}

.px-2.flex-grow-1::-webkit-scrollbar {
     display: none;
}

p.card-text.roboto-regular-16 {
     color: grey;
}

/* Error Page CSS Start*/
.errorPage {
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100vh;
     width: 100%;
     background-color: white !important;
}

.centerDiv {
     width: 395px;
}

.oops {
     font-size: 120px;
}

.errorBtn {
     width: 300px;
     height: 45px;
     background-color: #0046D4;
     color: white;
     border: none;
     border-radius: 35px;
     cursor: pointer;
     margin-top: 20px;
}

.errorBtn:hover {
     box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
}

.yourOrderNav {
     background-color: var(--buttonColor) !important;
     height: 7.4vh;
}

.item.card.mb-3.bg-white.c-pointer {
     /* width: 50%; */
     /* box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.3); */
     box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
     padding: 8px;
     border-radius: 17px;
     height: 100px;
}



/* Error Page CSS END*/

.cupponInput {
     border: none;
     border-radius: 4px 0 0 4px;
     /* padding: 2px 4px; */
}

.cupponBtn {
     border: 1px solid var(--primaryColor) !important;
     border-left: 0 !important;
     border-radius: 0 4px 4px 0 !important;
     padding: 2px 10px;
     background-color: var(--primaryColor);
     /* background-color: var(--buttonColor); */
     color: white;
}

.addressbar {
     display: flex;
     align-items: center;
     justify-content: space-between;
}

a:hover {
     /* color: #0a58ca; */
     color: #000000;
}

.rewardImage {
     width: 120px;
     height: 120px;
     border-radius: 8px;
}

.btnCart {
     display: none;
}

/* ================================= new css =============================== */

.imageNone {
     display: none !important;
}

.yourOrderTitle {
     font-size: 28px;
}

.orderPage {
     display: flex;
     flex-direction: column;
}

.orderDetails {
     display: flex;
     flex-wrap: wrap;
}

.productList {
     overflow-y: auto;
}

.scroll-item {
     overflow-y: auto;
}

.checkOutBtn button {
     font-size: 28px;
}

/* ================== product card css ================= */
.productImage {
     height: 80px;
     width: 80px;
     object-fit: cover;
}

.border-radius-img {
     border-radius: 8px;
}



/* .modal-content {
     width: 340px !important;
} */

/* ============================= Custom Modal CSS Start =================================== */
.custom-modal {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5);
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 2;
}

.loader {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(248, 247, 247, 0.744);
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 3;
}

.custom-modal-content-600 {
     background: white;
     border-radius: 10px;
     width: 700px;
     /* width: 385px; */
     /* max-width: 100%;
     height: 600px; */
}

.custom-modal-content-330 {
     background: white;
     border-radius: 10px;
     width: 415px;
     max-width: 100%;
     height: 378px;
}

.card-header {
     border-radius: 10px 10px 0 0 !important;
}

.custom-modal-header {
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 50%;
     border-radius: 10px !important;
}

.custom-modal-header img {
     object-fit: cover;
     border-radius: 10px 0 0 10px;
}

.cardDetails {
     height: 404px;
     padding: 10px;
}

.cardDetails-600 {
     /* height: 100%; */
     width: 50%;
     /* padding: 10px; */
}

.lessThan600 {
     height: 300px !important;
}

.cardDetails-330 {
     height: 376px;
     padding: 10px;
     width: 100%;
}

.formSelect {
     /* margin-top: 10px; */
     width: 100%;
     height: 40px;
     border: 2px solid #E8E7E8;
     border-radius: 14px;
     color: #202020;
}

.custom-modal-body {
     margin-bottom: 20px;
     overflow-y: auto;
     height: 303px;
}

.custom-modal-body::-webkit-scrollbar {
     display: none;
}

/* .custom-modal-body::-webkit-scrollbar-track {
     border-width: 1px;
     border-color: #EDEDED;
}

.custom-modal-body::-webkit-scrollbar-thumb {
     width: 9px;
     height: 30%;
     background-color: #C7C7C7;
     border-radius: 5px;
} */

.custom-modal-footer {
     display: flex;
     align-items: center;
     /* justify-content: center; */
     padding-top: 10px;
     border-top: 2px solid #E8E7E8;
     gap: 15px;
}

.addToCartBtn {
     border: none;
     background-color: var(--primaryColor);
     color: white;
     border-radius: 10px;
     width: 100%;
     height: 42px;
}

.modalXBtn {
     align-items: center;
     justify-content: center;
     /* background-color: #BEBEBE; */
     /* width: 25px;
     height: 25px; */
     top: 6px;
     right: 12px;
     /* border-radius: 50%; */
     cursor: pointer;
}

.modalXBtn svg {
     font-size: 25px;
     color: black;
}

.dashBtn,
.plusBtn {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 30px;
     height: 30px;
     border: 1px solid #E8E7E8;
     border-radius: 50%;
     cursor: pointer;
     /* box-shadow: 2px 2px 10px 0px; */
     box-shadow: 1px 2px 10px -3px;
     z-index: 2;
}

.dashBtn img,
.plusBtn img {
     width: 20px;
     height: 20px;
     color: #1f1f1f;
}

.totalQty {
     color: #202020;
     padding: 0 10px;
}

.totalPrice {
     color: #202020;
}

/* ============================= Custom Modal CSS END =================================== */



/* ================= Acordian CSS Start ================================ */
.accordion {
     border: 2px solid #E8E7E8;
     border-radius: 14px;
     /* margin: 10px 0; */
}

.accordion-header {
     /* display: flex; */
     align-items: center;
     padding: 8px;
     cursor: pointer;
     /* background-color: #f7f7f7; */
}

.accordion-header span p img {
     height: 16px;
     width: 20px;
     color: #000000;
}

.accordion-header input {
     margin-right: 10px;
}

.accordion-content {
     padding: 0 10px 10px 10px;
     background-color: #fff;
     border-radius: 14px;
}

.checkboxChild {
     border-top: 2px solid #E8E7E8;
}

.checkboxChild input[type="checkbox"] {
     width: 15px;
     height: 15px;
     border-width: 1px;
     border-color: #202020;
     border-radius: 2px;
}

.custom-control-label::after {

     position: absolute;
     top: .25rem;
     left: -1.5rem;
     display: block;
     width: 1rem;
     height: 1rem;
     content: "";
     background: no-repeat 50% / 50% 50%;

}

/* ================= Acordian CSS END ================================ */

/*================= header CSS =====================================*/
.header-div {
     position: fixed;
     width: 100%;
     z-index: 9999;
     padding: 5px;
}

.pt-40 {
     padding-top: 35px;
}

.Logo {
     margin: 15px 0;
}

.float-left {
     float: left;
}

.float-right {
     float: right;
}




/* =================\\\\\\\\\\\\ Custom Dropdown ///////////// ================= */
.custom-dropdown {
     position: relative;
     width: 100%;
     font-family: Arial, sans-serif;
     margin-top: 8px;
}

.dropdown-header {
     cursor: pointer;
     padding: 8px;
     background-color: #FFFFFF;
     border: 2px solid #E8E7E8;
     border-radius: 14px;
     display: flex;
     justify-content: space-between;
}

.arrow {
     margin-left: 10px;
}

.arrow.up {
     transform: rotate(180deg);
}

.dropdown-list {
     position: absolute;
     top: 100%;
     left: 0;
     width: 100%;
     background-color: #fff;
     border: 2px solid #E8E7E8;
     border-radius: 14px;
     border-top: none;
     list-style-type: none;
     padding: 0;
     margin-top: 4px;
     z-index: 1;
}

.dropdown-list li {
     padding: 2px 10px;
     cursor: pointer;
     margin: 0;
     color: #707070;
}

.dropdown-list li.selected {
     font-weight: bold;
}

.OverageImageUpload {
     width: 800px;
     height: 500px;
     background-color: #E8E7E8;
     border-radius: 10px;
     overflow-y: scroll;
}

.OverageImageUpload::-webkit-scrollbar {
     display: none;
}

.browserUploadBtn {
     background-color: #ffffff;
     color: var(--primaryColor);
     padding: 7px 12px;
     font-family: "Roboto", sans-serif !important;
     font-weight: 500 !important;
     font-style: normal !important;
     font-size: 17px !important;
     border: 2px solid var(--primaryColor);
     border-right: 0;
     border-radius: 5px 0 0 5px;
     cursor: pointer;
}

.cameraBtn {
     background-color: white;
     color: var(--primaryColor);
     padding: 7px 12px;
     font-family: "Roboto", sans-serif !important;
     font-weight: 500 !important;
     font-style: normal !important;
     font-size: 17px !important;
     border: 2px solid var(--primaryColor);
     border-left: 0;
     border-radius: 0 5px 5px 0;
     cursor: pointer;
}

.browserUploadBtn.active,
.cameraBtn.active {
     background-color: var(--primaryColor) !important;
     color: white !important;
}

.mainContent {
     margin: 12px 0;
     /* height: 305px; */
}

.frontDiv,
.backDiv {
     width: 372px;
     height: 289px;
     background-color: #FFFFFF;
     border: 2px dashed var(--primaryColor);
     border-radius: 8px;
}

.uploadImageShow img {
     width: 100%;
     height: 231px;
     border-radius: 6px 6px 0 0;
}

.confrimBtn,
.cancelBtn,
.clickBtn {
     width: 160px;
     padding: 6px 10px;
     background-color: var(--secondryColor);
     border: 0px;
     border-radius: 8px;
     font-family: "Roboto", sans-serif !important;
     font-weight: 500 !important;
     font-style: normal !important;
     font-size: 17px !important;
     margin: 0 5px;
     color: white;
}

.clickBtn {
     background-color: var(--primaryColor) !important;
}


.custom-file-upload {
     border: 1px solid #ccc;
     display: inline-block;
     padding: 6px 12px;
     cursor: pointer;
     background-color: #f0f0f0;
     border-radius: 8px;
     font-size: 16px;
     margin-bottom: 10px;
}

.custom-file-upload input[type="file"] {
     display: none;
}

.selected-file {
     margin-top: 10px;
     font-style: italic;
     display: none;
}

video.webcamCapture {
     width: 301px;
     height: 228px !important;
}

p.mb-0.float-left {
     color: red;
}

.idFakeImg {
     width: 66% !important;
}

.img-reward {
     border-radius: 8px !important;
     width: 100px !important;
     height: 100px !important;
}

.productList.col-12.col-md-6.bg-grey.p-3.scroll-item.m-0 {
     height: 92.6vh;
}

/* ============================= Reset Password Page Design Start =========================== */
.reset-password,
.resetConfirm-password {
     position: relative;
}

.passEye {
     position: absolute;
     right: 9px;
     top: 9px;
}

.passEye>svg,
.confirmPass>svg {
     font-size: 18px;
     cursor: pointer;
     color: #acafb3 !important;
}

.confirmPass {
     position: absolute;
     right: 9px;
     top: 9px;
}

.langImage {
     border: 2px solid var(--primaryColor) !important;
     border-radius: 50%;
}

/* ============================= Reset Password Page Design End =========================== */

/* ==================== Your order Page Css ========================*/

.yourOrderMainDiv {
     /* position: fixed; */
     background: url("./image/loginBackground.jpg");
     background-repeat: no-repeat;
     background-size: cover;
     height: 100vh;
     padding: 22px;
}

.cartDetails {
     display: flex;
     align-items: center;
     height: 100%;
}

.cartLeft {
     width: 70%;
     padding-right: 11px;
}

.productName {
     width: 56%;
}

.withOutImage {
     width: 67%;
}

.productIncrement {
     width: 30%;
}

.price {
     width: 20%;
     color: gray;
}

.cartRight {
     width: 30%;
     padding-left: 11px;
     height: 100%;
}

.deleteBtn {
     width: 4%;
}

.cartDetailsPage {
     height: 100%;
     background-color: white;
     border-radius: 10px;
     box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
     display: flex;
     flex-direction: column;
     position: relative;
}

.checkOutBtn {
     position: absolute;
     bottom: 14px;
     min-width: 92%;
}

.backToHome>svg {
     cursor: pointer;
}

.cartBody {
     overflow-y: scroll;
     height: 80vh;
}

.cartBody::-webkit-scrollbar {
     display: none;
}

.couponInputContainer,
.couponBlock {
     border-radius: 4px;
     box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.leftsideBox {
     overflow-y: scroll;
     box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
}

.righrtsideBox {
     box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
}

.leftsideBox::-webkit-scrollbar {
     display: none;
}

.dashBtnCart,
.plusBtnCart {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 25px;
     height: 25px;
     border: 1px solid #E8E7E8;
     border-radius: 50%;
     cursor: pointer;
     /* box-shadow: 2px 2px 10px 0px; */
     box-shadow: 1px 2px 4px -3px;
     z-index: 2;
}



.guestForm {
     overflow-y: scroll;
}

.guestNewDiv {
     position: fixed;
     background: url("./image/loginBackground.jpg");
     background-repeat: no-repeat;
     background-size: cover;
     width: 100%;
     height: 100vh;
     overflow-y: scroll;
}

.guestnewMainDiv {
     /* content: "";
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%); */
     width: 100%;
     /* height: 100%; */
     margin-top: 80px;
}

.formComponent {
     display: flex;
     /* align-items: center; */
     /* justify-content: space-between; */
     width: 100%;
}

.leftForm {
     width: 60%;
     margin: 25px 15px 30px 50px;
}

.rightOrderSummary {
     width: 33%;
     border-radius: 10px;
     margin: 25px 50px 30px 15px;
}

.progressForm {
     width: 100%;
     /* height: 100vh; */
     border-radius: 10px;
     box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
     /* margin-top: 159px; */
}

.leftsideBox {
     border-radius: 10px;
     /* height: 100%; */
}

.overpage .form {
     margin-left: 4px;
     margin-right: 4px;
}

.overpage {
     overflow-y: scroll;
     /* height: 79%; */
     margin-top: 20px;
     /* margin-left: 2px; */
}

.overpage2 {
     overflow: hidden;
     /* height: 82%; */
     margin-top: 20px;
}

.overpage::-webkit-scrollbar {
     display: none;
}

.progressBar2 {
     margin-top: -4px;
}

.progressHr {
     width: 200px;
}

.progressHr2 {
     width: 137px;
     color: white;
}

.progressNumber {
     padding: 2px 7px;
     background-color: #a0a0a0;
     border-radius: 50%;
     color: white;
}

.progressNumber2 {
     color: #a0a0a0;
     border-radius: 50%;
}

.progressName {
     position: absolute;
     left: 0;
     bottom: 0;
}

.confirmPageMainDiv {
     position: fixed;
     background: url("./image/loginBackground.jpg");
     background-repeat: no-repeat;
     background-size: cover;
     width: 100%;
     height: 100vh;
     overflow-y: scroll;
}

.confirmPageMainDiv::-webkit-scrollbar {
     display: none;
}

.orderConfirmed {
     background-color: white;
     border-radius: 10px;
}

table {
     font-family: arial, sans-serif;
     border-collapse: collapse;
     width: 100%;
}

td,
th {
     border-top: 1px solid #dddddd;
     border-bottom: 1px solid #dddddd;
     text-align: left;
     padding: 8px;
     font-family: "Roboto", sans-serif !important;
     font-weight: 500 !important;
     font-style: normal !important;
     font-size: 14px !important;
}

tr:nth-child(even) {
     background-color: #dddddd;
}

.orderDetailsBtn svg {
     font-size: 24px;
     color: var(--primaryColor);
     cursor: pointer;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-1vooibu-MuiSvgIcon-root {
     font-size: 27px !important;
}

.dropdown {
     position: relative;
     display: inline-block;
     margin-right: 10px;
}

.dropdown-content {
     display: none;
     position: absolute;
     background-color: #f9f9f9;
     min-width: 300px;
     box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
     border-radius: 10px;
     padding: 12px;
     z-index: 4;
}

.dropdown:hover .dropdown-content {
     display: block;
}

.timeheader {
     cursor: pointer;
     font-size: 14px;
     font-family: "Roboto", sans-serif !important;
     font-weight: 400 !important;
     font-style: normal !important;
}

.timelist {
     font-size: 14px;
     font-family: "Roboto", sans-serif !important;
     font-weight: 400 !important;
     font-style: normal !important;
     margin: 0;
}

.scroll-styled {
     overflow-y: scroll;
}

/* Track */
.scroll-styled::-webkit-scrollbar {
     width: 8px;
     /* Width of the scrollbar */
}

.scroll-styled::-webkit-scrollbar-thumb {
     background: var(--primaryColor);
     border-radius: 4px;
}

.loginError {
     color: rgb(235, 0, 0);
}

.btn-cancel {
     font-size: 14px;
     padding: 2px 10px;
     color: #fff !important;
     /* border-radius: 10px; */
     border: 0;
}

.backToHomeBtn {
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     background-color: aliceblue;
     height: 25px;
     width: 25px;
     border-radius: 50%;
     margin-top: 15px;
}

.backToHomeBtn>svg {
     font-size: 18px;
     color: #000000 !important;
}

@media screen and (min-width: 1400px) {
     .cardMain {
          height: 200px;
     }

     .custom-col-4 {
          flex: 0 0 25% !important;
          max-width: 25% !important;
     }

     .inputBox {
          padding: 9px 10px;
     }

     .checkOutBtn {
          min-width: 94%;
     }

     .inputBox {
          padding: 10px 12px !important;
     }

     .sidebarShow {
          width: 300px;
     }

     /* .billSection {
          height: 69% !important;
     } */
     .progressHr {
          width: 220px;
     }
}

@media (max-width: 1200px) {
     .left-section {
          left: -301px;
     }

     .sidebarShow {
          width: 250px;
          left: 0 !important;
          z-index: 2;
          /* height: 100vh; */
     }

     .sidebar {
          left: -100px;
     }

     .logoImg>img {
          height: 50px;
     }

     .hamBurger p svg {
          color: var(--primaryColor);
          width: 28px;
          cursor: pointer;
     }

     .cartBar p img {
          width: 26px;
          cursor: pointer;
     }

     .closeBtn {
          display: inline-block;
          top: 4px;
          right: 10px;
     }

     .right-section {
          width: 100%;
     }

     .cartBar {
          display: none;
     }
}

@media (max-width: 1115px) {
     .billSection {
          margin: 20px 0 20px 0;
          height: 51vh;
     }
}

@media (max-width: 1002px) {
     .btnCart {
          display: inline;
     }

     .cartButton {
          padding: 7px;
          border: 1px solid var(--primaryColor);
          border-radius: 4px;
          background-color: var(--primaryColor);
          color: #FFFFFF;
          margin-left: 10px;
          margin-right: 10px;
          width: 180px;
     }

     .cartButton svg {
          font-size: 25px;
     }

     .homeCart {
          display: none;
     }

     .cart {
          top: 0;
          right: 0;
          z-index: 1;
     }

     .productShow {
          width: 100%;
     }

     .productShowActive {
          width: 70%;

     }

     .cartPage {
          display: none;
     }

     .cartActive {
          display: inline;
          width: 30%;
          top: 0;
          right: 0;
          z-index: 1;
          height: 87.2vh;
     }

     .cartNumber {
          position: absolute;
          top: -6px;
          right: 2px;
          background-color: red;
          width: 18px;
          height: 18px;
          text-align: center;
          border-radius: 50%;
     }

     .order-card {
          margin: 0 4px !important;
     }
}

@media (max-width: 950px) {

     .productName {
          width: 42px;
     }

     .cartRight {
          width: 396px;
     }

     .productName {
          width: 42%;
     }

     .withOutImage {
          width: 53%;
     }

     .productIncrement {
          width: 30%;
     }

     .price {
          width: 20%;
          color: gray;
     }

     .cartRight {
          width: 400px;
          padding-left: 11px;
     }

     .deleteBtn {
          width: 4%;
     }
}

@media (max-width: 768px) {

     .tipButton button,
     .tipButton input {
          width: 45%;
          margin-bottom: 10px;
     }

     .productShow {
          width: 100%;
     }

     .productShowActive {
          width: 60%;
     }

     .cartPage {
          display: none;
     }

     .cartActive {
          display: inline;
          width: 50%;
     }

     .productList.col-12.col-md-6.bg-grey.p-3.scroll-item.m-0 {
          height: auto !important;
     }

     /* input.cupponInput.roboto-medium-16 {
          width: 200px !important;
     } */

     .btn-cancel {
          margin-top: 10px;
          margin-left: 0 !important;
     }
}

@media (max-width: 892px) {
     /* .sidebarChild {
          height: 90vh;
     } */

     .tipButton>button {
          width: 74px;
     }

     .billSection {
          margin: 25px 0;
     }

     .formComponent {
          flex-direction: column;
     }

     .leftForm {
          /* width: 92%;
          margin: 20px 0; */
          width: 95%;
          margin: 20px;
     }

     .rightOrderSummary {
          /* width: 92%;
          margin: 20px 0; */
          width: 95%;
          margin: 20px;
     }
}

@media (max-width: 862px) {
     .yourOrderMainDiv {
          flex-direction: column;
          overflow-y: scroll;
     }

     .cartDetailsPage {
          height: auto !important;
          margin-bottom: 10px !important;
     }

     .cartDetails {
          flex-direction: column;
     }

     .cartLeft {
          width: 100% !important;
          height: auto !important;
     }

     .cartRight {
          width: 100% !important;
          height: 100vh !important;
          padding: 0;
     }

     .cartLeft {
          padding: 0;
     }

     /* .cartBody {
          height: 63vh !important;
     } */

     .tipButton>button {
          width: 74px;
     }

     .billSection {
          margin: 55px 0 25px 0;
     }

     .cartBody {
          height: auto !important;
     }
}

@media (max-width: 800px) {
     .OverageImageUpload {
          width: 600px;
          height: 521px;
     }

     .frontDiv,
     .backDiv {
          width: 273px;
     }

     video.webcamCapture {
          width: 270px;
          border-radius: 6px 6px 0 0;
     }
}

@media (max-width: 800px) {
     .custom-modal-content-600 {
          width: 600px;
     }
}

@media (max-width: 680px) {
     .spanDiv {
          flex-direction: column !important;
     }

     .custom-modal-content-600 {
          width: 354px;
          /* width: 100%; */
     }

     .cardDetails-600 {
          height: 56vh;
          width: 100%;
     }

     .custom-modal-header {
          height: 175px;
          width: 100%;
     }
}

@media (max-width: 680px) {
     .productDetail>h5 {
          width: 36% !important;
     }

     .storeName {
          width: 260px;
          overflow: hidden;
     }

     .cartRight {
          padding: 0;
     }
}

@media (max-width: 600px) {
     .OverageImageUpload {
          width: 100%;
          height: 92%;
     }

     .browserUploadBtn {
          padding: 7px;
     }

     .mainContent {
          margin: 12px 0;
          /* height: 329px; */
          /* overflow-y: scroll; */
     }

     .frontDiv,
     .backDiv {
          height: auto;
     }

     .uploadImageShow img {
          height: 180px;
     }

     .browserUpload {
          flex-direction: column;
          gap: 5px;
     }

     video.webcamCapture {
          width: 232px;
          border-radius: 0 0 0 0;
     }
}

@media (max-width: 576px) {

     .p-2.flex-grow-1 {
          width: 100%;
     }

     .item {
          height: auto !important;
     }

     .border-radius-img {
          width: 100px;
          height: 100px;
          border-radius: 10px;
     }

     .card-body {
          /* flex-direction: column;
          align-items: flex-start; */
     }

     span.closeBtn {
          position: absolute;
          top: 0;
          right: 0;
     }

     .productDetail {
          /* flex-direction: column !important;
          justify-content: space-between !important;
          align-items: start !important; */
          width: 100%;
     }

     /* .p-2.flex-grow-1 {
          height: 120px !important;
     } */

     .topSection {
          width: 100%;
     }

     .searchBar input {
          margin-left: 16px;
          margin-right: 14px;
     }

     .custom-modal {
          align-items: end !important;
     }

     .custom-modal-content-600 {
          border-radius: 15px 15px 0 0 !important;
          width: 100% !important;
          /* max-width: 100% !important;
          height: 90% !important; */
     }

     .custom-modal-header {
          background-color: #fff !important;
          padding: 10px;
     }

     .custom-modal-header>img {
          justify-content: center !important;
          width: 100% !important;
          height: 100% !important;
          border-radius: 8px !important;
     }

     .modalXBtn {
          width: 30px !important;
          height: 30px !important;
          top: -44px !important;
          right: 46% !important;
     }

     h3.m-0.roboto-medium-28 {
          font-size: 16px;
     }

     .yourOrderTitle {
          font-size: 20px !important;
     }

     .addressText {
          font-size: 14px !important;
     }

     .phoneText {
          font-size: 14px !important;
     }

     .tipButton button,
     .tipButton input {
          width: 100%;
          margin-bottom: 10px;
     }

     .billSection ul {
          font-size: 16px;
     }

     .checkOutBtn button {
          font-size: 20px;
     }

     .tipTitle {
          font-size: 22px;
     }

     .billSection {
          padding: 1rem;
     }

     .billSection li {
          display: flex !important;
          align-items: center !important;
          justify-content: space-between !important;
          padding: 3px 0 !important;
     }

     .billSection li span {
          width: 100%;
          font-size: 15px;
          /* text-align: left; */
     }

     .billSection li span:last-child {
          text-align: end;
     }

     .productShow {
          width: 100%;
     }

     .productShowActive {
          width: 100%;
     }

     .cartPage {
          display: none;
     }

     .cartActive {
          display: inline;
          width: 95%;
     }

     .cartBar {
          /* display: inline; */
          display: none;
     }

     .d-flex.justify-content-between.align-items-center.p-2.border.border-1.border-start-0.bg-white {
          flex-direction: column !important;
     }

     .addressbar {
          flex-direction: column !important;
     }

     .addressText {
          width: 300px;
          overflow-x: scroll;
     }

     .addressText::-webkit-scrollbar {
          display: none;
     }

     .cardBody.w-100.d-flex.gap-2 {
          flex-direction: column;
     }

     /* .cardDetails-600 {
          height: 60vh !important;
     } */

     .cardDetails-330 {
          padding: 18px !important;
     }

     .custom-modal-content-330 {
          background: white;
          border-radius: 15px 15px 0 0 !important;
          width: 100% !important;
          max-width: 100%;
          height: 329px;
     }

     .productShow {
          height: 89vh !important;
     }

     /* =================== modal Responsive code in mobile =================== */

     .custom-modal-content {

          width: 350px !important;
          max-width: 100% !important;
          height: 530px !important;
     }

     /* .custom-modal-header {
          width: 415px !important;

     } */

     .cardDetails {
          height: 333px !important;
     }

     .couponSection {
          flex-direction: column !important;
     }

     .searchBar {
          flex-direction: column;
          gap: 10px;
     }

     .btnCart {
          width: 100%;
          padding: 0 14px 0 16px;
     }

     .cartButton {
          width: 100%;
          margin: 0;
     }

     .catagoryList {
          margin: 0 14px 0 16px;
     }

     .leftForm {
          width: auto !important;
     }

     .rightOrderSummary {
          width: auto !important;
     }
}

@media (max-width: 425px) {
     .productDetail {
          flex-direction: column !important;
          align-items: start !important;
          justify-content: start !important;
     }

     .cartBody {
          height: auto !important;
     }

     .border-radius-img {
          width: 170px !important;
          height: 170px !important;
     }

     .productDetail>h5 {
          width: 100% !important;
     }

     .productDetail>div {
          width: 100% !important;
     }

     .productDetail>p {
          width: 100% !important;
     }

     .productDetail>span {
          width: 100% !important;
     }

     .storeName {
          width: 200px;
          overflow: hidden;
     }
}

@media (max-width: 376px) {
     .border-radius-img {
          width: 143px !important;
          height: 143px !important;
     }
}

@media (max-width: 321px) {
     .custom-modal-content {
          width: 310px !important;
          max-width: 100% !important;
          height: 530px !important;
     }

     .custom-modal-header {
          width: 310px !important;
          height: 200px !important;

     }

     .cardDetails {
          height: 333px !important;
     }

     .border-radius-img {
          width: 100px !important;
          height: 100px !important;
     }
}

@media (max-width: 320px) {
     .centerDiv {
          width: 275px;
     }

     .oops {
          font-size: 100px;
     }

     .errorBtn {
          width: 220px;
     }
}

/* .emptyCardTxt {
     display: flex;
     flex-direction: column;
     text-align: center;
     justify-content: center;
} */


.order-card {
     display: flex;
     flex-direction: column;
     /* border: 1px solid #ddd; */
     border-radius: 10px;
     overflow: hidden;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
     margin: 10px;
     width: 100%;
     transition: transform 0.3s ease;
     flex: 1;
     background-color: #fff;
     cursor: pointer;
}

.order-card:hover {
     transform: scale(1.01);
}

.order-image {
     width: 100%;
     height: 140px;
     object-fit: cover;
}

.order-image_blur {
     width: 100%;
     height: 140px;
     object-fit: cover;

     -webkit-filter: grayscale(100%);
     -moz-filter: grayscale(100%);
     -o-filter: grayscale(100%);
     -ms-filter: grayscale(100%);
     filter: grayscale(100%);

}

.order-info {
     padding: 8px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     flex-grow: 1;
}

.order-name {
     margin-bottom: 8px;
     min-height: 45px;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 2;
     overflow: hidden;
     text-overflow: ellipsis;
}

.bottom_line {
     display: flex;
     align-items: center;
     justify-content: space-between;
}

.order-category {
     color: #555;
     margin: 0;
}

.order-price {
     font-weight: bold;
     margin: 0;
}

.order-list {
     display: flex;
     flex-direction: column;
     align-items: center;
     padding: 16px;
     gap: 16px;
}

/* Responsive */
@media (min-width: 600px) {
     .order-list {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
     }

     .order-card {
          flex: 1 1 calc(50% - 20px);
     }
}

@media (min-width: 900px) {
     .order-card {
          flex: 1 1 calc(33.333% - 20px);
     }
}

/* Custom Confirm Button */
.my-confirm-button {
     background-color: var(--primaryColor);
     /* Primary color */
     color: white;
     border: none;
     padding: 10px 20px;
     border-radius: 5px;
     font-size: 1rem;
     cursor: pointer;
     transition: background-color 0.3s ease;
}


/* Custom Cancel Button */
.my-cancel-button {
     background-color: #e0e0e0;
     color: #333;
     border: none;
     padding: 10px 20px;
     border-radius: 5px;
     font-size: 1rem;
     cursor: pointer;
     transition: background-color 0.3s ease;
}