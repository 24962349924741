.bg-f0f3f4 {
    background: #f0f3f4;
}

.logoOrder {
    width: 30%;
    background-color: var(--primaryColor);
    /* background-color: #a42f1e; */
}

.col-div-3 {
    width: 32%;
    float: left;
    margin-right: 2%;
}

.col-div-3.last {
    margin-right: 0;
}

.header-div {
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.header-div {
    padding: 5px;
}

.header-div {
    /* font-family: 'helveticaneuebold'; */
    font-size: 22px;
    text-transform: uppercase;
    color: #333;
    line-height: 1.2;
    z-index: 1;
}

@media (min-width: 576px) {
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

.header-div a {
    color: #333;
    width: 25px;
    height: 25px;
    text-align: center;
}

.float-left {
    float: left;
}

a {
    text-decoration: none;
}

.header-div h2 {
    font-size: 22px;
}

.pt-40 {
    padding-top: 35px;
}

.select.custom-select {
    position: relative;
    display: flex;
    width: 100%;
    background: transparent;
    overflow: hidden;
    border-radius: 5px;
}

.form-control,
.select.custom-select {
    border: 1px solid #EDEDED;
    font-size: 14px;

}

.loginSelect {
    position: absolute;
    right: 18;
    top: 60px;
}

.w-100px {
    width: 70px !important;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.float-right {
    float: right;
}

.select {
    position: relative;
    display: flex;
    width: 100%;
    height: 2.5em;
    line-height: 2.5;
    background: #fff;
    overflow: hidden;
    outline: none;
    border-radius: 3px;
}

@media only screen and (max-width: 767px) {

    .LoginRegisterBlock,
    .LoginRegisterBlock.OrderConfirmedBlock,
    .w-175 {
        width: 100%;
        padding: 0;
    }
}

.LoginRegisterBlock {
    width: 100%;
    float: left;
    padding: 0 80px;
}

.Logo {
    margin: 15px 0;
}

a {
    text-decoration: none;
}

@media only screen and (max-width: 767px) {
    .LoginRegisterBlock .btn-yellow {
        padding: .5rem 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .w-m-100 {
        width: 100% !important;
    }
}

@media only screen and (max-width: 890px) {

    .btn-yellow,
    .btn-yellow:hover {
        font-size: 14px;
        padding: 0 10px;
    }
}

.btn-yellow,
.btn-yellow:hover {
    font-size: 16px;
    background: var(--primaryColor);
    /* background: #a42f1e; */
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    padding: 8px 10px;
    color: #fff;
    border-radius: 5px;
    border: 0;
    padding: .5rem 1rem !important;
}

.page-title h2 {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid var(--primaryColor);
    /* border-bottom: 2px solid #a42f1e; */
    line-height: 0.1em;
    text-transform: uppercase;
    /* font-family: 'helveticaneuebold'; */
    font-size: 21px;
    color: var(--primaryColor);
    /* color: #a42f1e; */
}

.btn-blue,
.btn-blue:focus,
.btn-blue:hover {
    background: #003F96;
}

.LoginRegisterForm {

    font-size: 13px;
    color: #333;
}

.float-left {
    float: left;
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.3rem;
    padding-left: 1.3rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.hr {
    /* border-bottom: 3px solid var(--primaryColor); */
    border-bottom: 3px solid #a42f1e;
}

@media only screen and (max-width: 767px) {
    .w-m-100 {
        width: 100% !important;
    }
}

@media only screen and (max-width: 767px) {
    .LoginRegisterBlock .btn-yellow {
        padding: .5rem 1rem;
    }
}

@media only screen and (max-width: 890px) {

    .btn-yellow,
    .btn-yellow:hover {
        font-size: 14px;
        padding: 0 10px;
    }
}

.LoginRegisterForm .custom-control-label::before,
.LoginRegisterForm .custom-control-label::after {
    width: 1.3rem;
    height: 1.3rem;
}

.custom-checkbox .custom-control-label::before {
    border: 2px solid var(--primaryColor);
}

.bg-grey {
    background-color: #DDDDDD;
}

@media only screen and (max-width: 890px) {
    .fixed-bottom-div-desktop {
        display: block;
    }
}

.fixed-bottom-div-mobile,
.fixed-bottom-div-desktop {
    padding-top: 5px;
    padding-bottom: 5px;
}

.fixed-bottom-div {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 3px 8px;
    background: #333333;
    color: #fff;
    width: 100%;
    z-index: 1100;
}

.fixed-bottom-div p {
    font-size: 13px;
    /* font-family: 'helveticaneuebold'; */
    margin-top: 4px;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}