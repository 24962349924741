.bg-f0f3f4 {
    background: #f0f3f4;
}

.header-div {
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.header-div {
    padding: 5px;
}

.header-div {
    /* font-family: 'helveticaneuebold'; */
    font-size: 22px;
    text-transform: uppercase;
    color: #333;
    line-height: 1.2;
    z-index: 1;
}

.header-div a {
    color: #333;
    width: 25px;
    height: 25px;
    text-align: center;
}

.float-left {
    float: left;
}

a {
    text-decoration: none;
}

.header-div h2 {
    font-size: 22px;
}

.pt-40 {
    padding-top: 35px;
}

.bg-f0f3f4 {
    background: #f0f3f4;
}

@media only screen and (max-width: 767px) {

    .LoginRegisterBlock,
    .LoginRegisterBlock.OrderConfirmedBlock,
    .w-175 {
        width: 100%;
        padding: 0;
    }
}

.LoginRegisterBlock {
    width: 100%;
    float: left;
    padding: 0 80px;
}

.LoginRegisterForm {

    font-size: 13px;
    color: #333;
}

.float-left {
    float: left;
}

@media only screen and (max-width: 767px) {

    .LoginRegisterBlock,
    .LoginRegisterBlock.OrderConfirmedBlock,
    .w-175 {
        width: 100%;
        padding: 0;
    }
}

.text-left {
    text-align: left !important;
}

.user_info p {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 1.2;
}

.user_info p a {
    font-size: 20px;
}

@media only screen and (max-width: 767px) {
    .w-m-42 {
        width: 42%;
    }
}

/* .color333 {
    color: #333 !important;
} */

.w-30 {
    width: 33%;
}

.w-175 {
    width: 175px;
}