.bg-f0f3f4 {
    background: #f0f3f4;
}

.header-div {
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.header-div {
    padding: 10px;
}

.header-div {
    /* font-family: 'helveticaneuebold'; */
    font-size: 22px;
    text-transform: uppercase;
    color: #333;
    line-height: 1.2;
    z-index: 1;
}

.header-div a {
    color: #333;
    width: 25px;
    height: 25px;
    text-align: center;
}

.float-left {
    float: left;
}

a {
    text-decoration: none;
}

.header-div h2 {
    font-size: 22px;
}

.pt-40 {
    padding-top: 46px;
}

.vscroll {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.vh1-100 {
    height: 93vh;
}

#my-modal-signIn {
    width: 300px !important;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
}

@media only screen and (max-width: 890px) {
    .LoginRegisterBlock {
        padding: 0 15px !important;
    }
}

.LoginRegisterBlock {
    width: 100%;
    float: left;
    padding: 0 80px;
}

.LoginRegisterForm {

    font-size: 13px;
    color: #333;
}

.LoginRegisterForm h3,
.LoginRegisterForm h2 {
    color: var(--primaryColor);
    margin: 20px 0 13px 0;
    text-align: center;
}

/* .LoginRegisterForm .form-group label {
    font-size: 14px;

    color: #333;
    cursor: pointer;
} */

/* .fontHelveticaNuueBold {
    font-family: 'helveticaneuebold' !important;
} */

.col-div-3 {
    width: 32%;
    float: left;
    margin-right: 2%;
}

.col-div-3.last {
    margin-right: 0;
}

@media only screen and (max-width: 890px) {

    .btn-lg,
    .btn-lg:hover {
        padding: .5rem 1rem;
    }
}

@media only screen and (max-width: 890px) {

    .btn-yellow,
    .btn-yellow:hover {
        font-size: 14px;
        padding: 0 10px;
    }
}

.btn-yellow,
.btn-yellow:hover {
    font-size: 14px;
    background: var(--primaryColor);
    padding: 2px 10px;
    color: #fff;
    border-radius: 5px;
    border: 0;
}

.LoginRegisterForm a.font-14 {
    font-size: 14px;
}

.LoginRegisterForm label a {
    font-size: 14px;
}

/* .LoginRegisterForm a {
    color: #a42f1e;
font-size: 13px;
font-family: 'helveticaneuebold';
}

*/


.btn-lg,
.btn-lg:hover {
    padding: .5rem 1rem;
    font-size: 1.15rem;
}

#my-modal-addcard h3,
#my-modal-signIn h3 {
    font-size: 20px;
    /* font-family: 'helveticaneuebold'; */
    color: #333;
    margin-bottom: 0.6rem;
}

#my-modal-signIn .btn-yellow,
#my-modal-signIn .btn-yellow:hover {
    font-size: 15px;
    padding: 7px 10px;
}

#my-modal-signIn .LoginRegisterForm .form-group label {
    font-size: 12px;
}

.custom-control-input {
    position: absolute;
    left: 2px;
    top: 3px;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 1;
    cursor: pointer;
}

#my-modal-signIn .custom-control {
    padding-left: 1.6rem;
}

input,
select {
    vertical-align: middle;
}