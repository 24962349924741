.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 2.3rem;
    outline: 0;
}

.modalMainDiv {
    background-color: white;
    /* height: 350px;
    width: 340px; */
    border-radius: 12px;
    text-align: center;
    overflow-y: scroll;
}

.modalMainDiv::-webkit-scrollbar {
    display: none;
}


.modal-body {
    padding: 0;
}

.float-left {
    float: left;
}

.p-10 {
    padding: 10px;
}

.tab-mnu {
    border: 2px solid var(--primaryColor);
    float: left;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

ul {
    margin: 0;
    padding: 0;
}

.tab-mnu li {
    /* margin: 0 5px 0 0; */
    min-width: 10%;
    height: 30px;
    /* color: #a42f1e; */
    color: var(--primaryColor);
    position: relative;
    text-align: center;
    font-weight: bold;
    padding: 0px;
    display: inline-block;

    font-size: 12px;
}

.tab-mnu li {
    background: #fff;
    width: 50%;
    float: left;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 0;
    cursor: pointer;
}

.tab-mnu li {
    /* margin: 0 5px 0 0; */
    min-width: 10%;
    height: 30px;
    /* color: #a42f1e; */
    color: var(--primaryColor);
    position: relative;
    /* text-align: center; */
    font-weight: bold;
    padding: 0px;
    display: inline-block;
    font-size: 12px;
}

h4,
.tab-mnu li,
.tabViewOrderBlock h3 {
    font-size: 17px;
    /* font-family: 'helveticaneuebold'; */
    color: #333;
}

.tab-mnu input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
    position: absolute;
    cursor: pointer;
    border: none !important;
    border-radius: 8px !important;
}

.tab-mnu label {
    cursor: pointer;
    z-index: 90;
    padding-top: 2px;
}

.tab-mnu label,
.tab-mnu input {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

input,
select {
    vertical-align: middle;
}

.tab-mnu label,
.tab-mnu input {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}


input,
select {
    vertical-align: middle;
}

.tab-mnu input[type="radio"]:checked+label {
    background: var(--primaryColor);
    color: #fff;
    height: 100%;
    padding-top: 2px !important;
}

.tabViewOrderBlock p {
    font-size: 12px;

    font-style: italic;
    color: #333;
    line-height: 1;
}

/* /// */
.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)>.swal2-modal {
    margin: auto;
}

.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    width: 32em;
    max-width: 100%;
    padding: 0.8em;
    border: none;
    border-radius: .3125em;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
}

.swal2-title {
    position: relative;
    max-width: 100%;
    margin: 0 0 .4em;
    padding: 0;
    color: #595959;
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.swal2-input[type=number] {
    max-width: 10em;
}

.swal2-input {
    height: 2.625em;
    padding: 0 .75em;
}

.swal2-actions {
    display: flex;
    z-index: 1;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1.25em auto 0;
    padding: 0 1.6em;
}

.swal2-cancel {
    margin-left: 10px !important;
}

.link_button {

    font-size: 14px;
    background: var(--primaryColor);
    padding: 2px 10px;
    color: #fff;
    border-radius: 5px;
    border: 0;
}

.link_button2 {
    font-size: 14px;
    background: var(--primaryColor);
    padding: 2px 10px;
    color: #fff;
    border-radius: 5px;
    border: 0;
    width: 280px;
    margin: 15px 0 0 0 !important;
}

.swal2-file,
.swal2-input,
.swal2-textarea {
    box-sizing: border-box;
    width: 100%;
    transition: border-color .3s, box-shadow .3s;
    border: 1px solid #d9d9d9;
    border-radius: .1875em;
    background: inherit;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .06);
    color: inherit;
    font-size: 1.125em;
}

.swal2-checkbox,
.swal2-file,
.swal2-input,
.swal2-radio,
.swal2-select,
.swal2-textarea {
    margin: 1em auto;
}

input,
select {
    vertical-align: middle;
}

.swal2-content,
#swal2-html-container {
    font-size: 1.1em;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.swal2-validation-message {
    display: none;
    align-items: center;
    justify-content: center;
    /* margin: 0 -2.7em; */
    padding: .625em;
    overflow: hidden;
    background: #f0f0f0;
    color: #666;
    font-size: 1em;
    font-weight: 300;
}

.orderTypeButton {
    font-size: 14px;
    background: var(--primaryColor);
    padding: 2px 10px;
    color: #fff;
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.orderTypeButton:hover {
    color: black;
}

button.swal2-confirm.swal2-styled {
    /* background-color: var(--primaryColor); */
    background-color: var(--primaryColor) !important;
}

.orderTypeSelect {
    color: #9da0a4;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
}

.closeModal {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.closeModal svg {
    font-size: 24px;
}