.bg-f0f3f4 {
    background: #F2F1F2;
}

.header-div {
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.header-div {
    padding: 5px;
}

.header-div {
    /* font-family: 'helveticaneuebold'; */
    font-size: 22px;
    text-transform: uppercase;
    color: #333;
    line-height: 1.2;
    z-index: 1;
}

.header-div a {
    color: #333;
    width: 25px;
    height: 25px;
    text-align: center;
}

.float-left {
    float: left;
}

a {
    text-decoration: none;
}

.header-div h2 {
    font-size: 22px;
}

.pt-40 {
    padding-top: 35px;
}

.vscroll {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.vh1-100 {
    height: 100vh;
}

@media only screen and (max-width: 890px) {

    .LoginRegisterBlock.OrderConfirmedBlock,
    .RewardsBlockMain {
        padding: 0 30px;
    }
}

@media only screen and (max-width: 890px) {
    .LoginRegisterBlock {
        padding: 0 15px;
    }
}

.LoginRegisterBlock {
    width: 100%;
    float: left;
    padding: 0 80px;
}

@media only screen and (max-width: 890px) {

    .LoginRegisterBlock.OrderConfirmedBlock,
    .RewardsBlockMain {
        padding: 0 30px;
    }
}

@media only screen and (max-width: 890px) {
    .LoginRegisterBlock {
        padding: 0 15px;
    }
}

.LoginRegisterBlock {
    width: 100%;
    float: left;
    padding: 0 80px;
    margin-top: 40px;
}

.LoginRegisterForm {

    font-size: 13px;
    color: #333;
}

.float-left {
    float: left;
}

@media only screen and (max-width: 890px) {
    .points_box {
        border-radius: 50px;
    }
}

.points_box {
    border: 2px solid #555555;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -khtml-border-radius: 20px;
    margin-bottom: 0px;
}

@media only screen and (max-width: 890px) {
    .points_box {
        border-radius: 50px;
    }
}

@media only screen and (max-width: 890px) {
    .title_points {
        width: 150px;
        /* margin-left: -50px; */
    }
}

h3.text-center.m-0.text-uppercase.roboto-medium-22 {
    width: 134px;
}

.title_points {
    position: absolute;
    background: #fff;
    transform: translate(-50%, -50%);
    left: 50%;
    padding: 0 10px;
}

@media only screen and (max-width: 890px) {
    .LoginRegisterForm .points_box h3 {
        font-size: 13px;
    }
}

@media only screen and (max-width: 890px) {
    .LoginRegisterForm .points_box h3 {
        font-size: 13px;
    }
}

@media only screen and (max-width: 890px) {
    .LoginRegisterForm .points_box h2 {
        font-size: 16px;
        margin: 10px 0 5px;
    }
}

@media only screen and (max-width: 890px) {
    .LoginRegisterForm .points_box h2 {
        font-size: 16px;
        margin: 10px 0 5px;
    }
}

.LoginRegisterForm h2 {
    font-size: 30px;
    text-transform: uppercase;
    margin: 22px 0 10px;
}

.LoginRegisterForm h3,
.LoginRegisterForm h2 {
    /* font-size: 20px; */
    color: var(--primaryColor);
    /* font-family: 'helveticaneuebold'; */
    margin: 20px 0 13px 0;
    text-align: center;
}

p.h4-hr {
    overflow: hidden;
    position: relative;
    margin: 10px 0;
}

p.h4-hr::after {
    content: '';
    width: 100%;
    height: 2px;
    background: #333;
    position: absolute;
    top: 50%;
    margin-left: 5px;
}

.shadow1 {
    -webkit-box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 0 5px 2px rgb(0, 0, 0, .1);
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
    border: 1px solid #eee;
}

.productsBlock {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.ProductHome {
    min-height: 145px !important;
}

@media only screen and (max-width: 1023px) {
    .productsBlock {
        border-radius: 5px;
    }
}

.productsBlock {
    float: left;
    position: relative;
    -webkit-box-shadow: 0 0 5px 2px rgb(0, 0, 0, .1);
    -moz-box-shadow: 0 0 5px 2px rgb(0, 0, 0, .1);
    box-shadow: 0 0 5px 2px rgb(0, 0, 0, .1);
    border: 1px solid #eee;
    margin-bottom: 12px;
    background: #fff;
    width: 98.7%;
    cursor: pointer !important;
}

.btn_locked,
.btn_locked:hover,
.btn_locked:focus {
    background: #EFEFEF !important;
}

.productsBlock .overlay-img {
    /* background: rgba(0, 0, 0, 0.5) url(../img/locked-icon.png) center no-repeat; */
    background: rgba(0, 0, 0, 0.5) url(../../public/img/locked-icon.png) center no-repeat;
    height: 100%;
    width: 100%;
    overflow: auto;
    margin: auto;
    position: absolute;
    float: right;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
}

.productsBlock .product-img {
    position: relative;
    margin-left: 5px;
    border-radius: 8px;
}

.productsBlockRight {
    float: right;
    /* width: 30%; */
    width: 100px;
    height: 100px;
}

@media only screen and (max-width: 1023px) {
    .productsBlock .overlay-img {
        background: rgba(0, 0, 0, 0.5) url(../../public/img/locked-icon-small.png) center no-repeat;
    }
}

@media only screen and (max-width: 890px) {
    .ProductLeftBottom {
        right: 32%;
        width: 66%;
    }
}

.no-image .ProductLeftBottom {
    left: 0;
    width: 100%;
    padding-left: 25px !important;
    padding-right: 10px;
}

.ProductLeftBottom {
    float: left;
    position: absolute;
    bottom: 10px;
    right: 22%;
    width: 75%;
    left: auto;
}

.w-120px {
    width: 120px;
}

.productsBlockLeft p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* height: 95px; */
    overflow: hidden;
    -webkit-box-orient: vertical;
}

/* .ProductLeftBottom p,
p.p_red {
    color: #EA0707;

    font-size: 15px;
    margin-top: 2px;
} */

.cardBottom>p,
p.p_red {
    color: #EA0707;
    font-size: 15px;
    margin-top: 2px;
}