.grey {
    color: rgb(164, 164, 164);
}

.header-div {
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.header-div {
    padding: 5px;
}

.header-div {
    /* font-family: 'helveticaneuebold'; */
    font-size: 22px;
    text-transform: uppercase;
    color: #333;
    line-height: 1.2;
    z-index: 1;
}

.header-div a {
    color: #333;
    width: 25px;
    height: 25px;
    text-align: center;
}

.float-left {
    float: left;
}

a {
    text-decoration: none;
}

.header-div h2 {
    font-size: 22px;
}

.pt-40 {
    padding-top: 35px;
}

.vscroll {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.vh1-100 {
    height: 100vh;
}

@media only screen and (max-width: 767px) {

    .LoginRegisterBlock,
    .LoginRegisterBlock.OrderConfirmedBlock,
    .w-175 {
        width: 100%;
        padding: 0;
    }
}

.LoginRegisterBlock {
    width: 100%;
    float: left;
    padding: 0 80px;
}

.LoginRegisterForm {

    font-size: 13px;
    color: #333;
}

.float-left {
    float: left;
}

.LoginRegisterForm h3,
.LoginRegisterForm h2 {
    font-size: 20px;
    color: var(--primaryColor);
    /* color: #a42f1e; */
    /* font-family: 'helveticaneuebold'; */
    margin: 20px 0 13px 0;
    text-align: center;
}

.form-control {
    padding: .375rem .35rem;
}

.form-control,
.select.custom-select {
    border: 1px solid #EDEDED;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.order-history p {
    text-align: left;
    font-size: 14px;
    /* font-family: 'helveticaneuebold'; */
    margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
    .w-m-60 {
        width: 60%;
    }
}

@media only screen and (max-width: 767px) {
    .LoginRegisterBlock .btn-yellow {
        padding: .5rem 1rem;
    }
}

@media only screen and (max-width: 890px) {

    .btn-yellow,
    .btn-yellow:hover {
        font-size: 14px;
        padding: 0 10px;
    }
}

.btn-yellow,
.btn-yellow:hover {

    font-size: 14px;
    background: var(--primaryColor) !important;
    /* background: #a42f1e; */
    padding: 2px 10px;
    color: #fff !important;
    border-radius: 10px;
    border: 0;
}

.ViewOrderBlock.Checkout_info {
    border: 0;
}

li {
    list-style: none;
}

ul li {
    /* font-family: 'helveticaneuebold'; */
    font-size: 17px;
    color: #333;
    margin-bottom: 5px;
}

.order-detail .hr_grey {
    margin-bottom: 0;
}

.hr_grey {
    border-top: 2px solid #F0F3F4;
    padding: 0 0 10px 0;
    margin-top: 10px;
}

.btn-lg,
.btn-lg:hover {
    padding: .5rem 1rem;
    font-size: 1.15rem;
}

.w-37 {
    width: 43%;
}