@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

html,
body,
#root {
  height: 100%;
}

div#root {
  width: 100%;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-regular-14 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 14px !important;
}

.roboto-regular-16 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 16px !important;
}

.roboto-regular-20 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.roboto-regular-26 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-medium-11 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 1;
}

.roboto-medium-14 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 14px !important;
  letter-spacing: 1 !important;
}

.roboto-medium-16 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 1;
}

.roboto-medium-18 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  letter-spacing: 1;
}

.roboto-medium-20 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 20px !important;
}

.roboto-medium-22 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 22px !important;
}

.roboto-medium-28 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 28px !important;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-bold-16 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
}

.roboto-bold-18 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-size: 18px !important;
}

.roboto-bold-20 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-size: 20px !important;
}

.roboto-bold-24 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-size: 24px !important;
}

.roboto-bold-28 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-size: 28px !important;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-regular-italic-12 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: italic !important;
  font-size: 12px !important;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.bg-green {
  /* background-color: #a42f1e; */
  background-color: var(--primaryColor);
}

.text-green {
  /* background-color: #a42f1e; */
  color: var(--primaryColor);
}

.bg-white {
  background-color: #FFFFFF
}

.bg-grey {
  background-color: #EEEEEE;
}

.borderGrey {
  /* border-width: 1px; */
  border-color: #D6D6D6 !important
}

.green-border {
  border-width: 1px;
  /* border-color: #1E4E29; */
  border-color: var(--primaryColor);
  border-radius: 10px;
}

.border-redius-img {
  border-radius: 8px 0px 0px 8px;
}

.c-pointer {
  cursor: pointer;
}

hr {
  height: 2px !important;
}

@media (min-width: 1500px) {
  .nav li {
    height: 61px;
  }

  .roboto-medium-20 {
    font-size: 28px !important;
  }

  .roboto-medium-16 {
    font-size: 20px !important;
  }

  .phoneText {
    width: 218px !important;
  }
}

.cursor_pointer {
  cursor: pointer;
}

input[type="checkbox"] {
  accent-color: var(--primaryColor)
}