.LoginRegisterForm {

    font-size: 13px;
    color: #333;
}

.forgotPass {
    color: var(--primaryColor) !important;
}

.rememberMe {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-size: 14px !important;
}

.btnLogin {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-size: 17px !important;
    color: white;
}

.createNewAccount {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-size: 14px !important;
}

.page-title h2 {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid var(--primaryColor);
    line-height: 0.1em;
    text-transform: uppercase;
    font-size: 21px;
    color: var(--primaryColor);
}

.page-title h2 span {
    background: #fff;
    padding: 0 10px;
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.3rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.btn-yellow,
.btn-yellow:hover {

    font-size: 14px;
    background: #a42f1e;
    padding: 2px 10px;
    color: #fff;
    border-radius: 5px;
    border: 0;
}

.btn-yellow:hover {
    opacity: 0.9;
}

.btn-blue,
.btn-blue:focus,
.btn-blue:hover {
    background: #003F96;
}

.language {
    position: absolute;
    top: 10px;
    right: 10px;
}

.backButton {
    position: absolute;
    top: -16px;
    left: -6px;
    background-color: white;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.backButtonSign {
    position: absolute;
    top: -2px;
    left: 0;
    background-color: white;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media only screen and (max-width: 890px) {

    .btn-lg,
    .btn-lg:hover {
        padding: .5rem 1rem;
    }
}

@media only screen and (max-width: 890px) {

    .btn-yellow,
    .btn-yellow:hover {
        font-size: 14px;
        padding: 0 10px;
    }
}

.loginSelect {
    position: absolute;
    right: 0;
    top: 45px;
}

.loginSelect select {
    /* font-size: 11px; */
    width: 35px;
    border: 1px solid #333;
    border-radius: 50% !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal;
    margin-right: 10px;
    font-size: 16px !important;
    cursor: pointer !important;
}

.w-m-100 {
    width: 100%;
}

.bg-grey {
    background-color: #DDDDDD;
}

@media only screen and (max-width: 890px) {
    .fixed-bottom-div-desktop {
        display: block;
    }
}

.fixed-bottom-div-desktop {
    padding-top: 5px;
    padding-bottom: 5px;
}

.fixed-bottom-div {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 3px 8px;
    background: #333333;
    color: #fff;
    width: 100%;
    z-index: 1100;
}

.bg-grey {
    background-color: #DDDDDD !important;
}

.languageDropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 1px solid #ccc;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
}

.flagImagebutton {
    background-color: white;
    border: none;
    width: 35px;
    position: relative;
}

.loginButton {
    border: 1px solid var(--primaryColor);
    background-color: var(--primaryColor);
    border-radius: 15px;
    color: #FFFFFF;
    padding: 3px 20px;
}

.homeFlagImagebutton {
    /* background-color: var(--primaryColor); */
    background-color: transparent;
    border: none;
    width: 35px;
    position: relative;
}

.flagImageDiv {
    z-index: 2000;
    position: absolute;
    border: 1px solid rgb(188, 188, 191);
    top: 42px;
    right: 7px;
    width: 130px;
    /* height: 65px; */
    border-radius: 8px;
    background-color: white;
    padding: 5px;
}

.flagImageDiv>li {
    cursor: pointer;
}

.mainLogin {
    position: relative;
    background: url("../image/loginBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.signupPage {
    position: fixed;
    background: url("../image/loginBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.loginLogo {
    position: absolute;
    top: 15px;
    left: 30px;
    z-index: 2;
    cursor: pointer;
}

.loginLogo img {
    width: 90px;
}


.loginCard {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    height: 80vh;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 1px #e2e2e2;
    background-color: white;
    display: flex;
    align-items: center;
}

.loginCard50 {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 80vh;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 1px #e2e2e2;
    background-color: white;
    display: flex;
    align-items: center;
}

.signupCard {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    height: 100vh;
    overflow-y: scroll;
    /* border-radius: 10px;
    box-shadow: 0px 1px 4px 1px #e2e2e2;
    background-color: white;
    display: flex;
    align-items: center; */
}

.signupCard::-webkit-scrollbar {
    display: none;
}

.formDiv {
    width: 50%;
    height: 100%;
    background-color: aliceblue;
    border-radius: 10px 0 0 10px;
}

.formDiv100 {
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    border-radius: 10px;
}

.signupForm {
    /* display: flex;
    align-items: center;
    gap: 20px; */
    width: 100%;
    /* overflow-y: scroll; */
    /* background-color: aliceblue; */
    background-color: #f5f5f5;
    border-radius: 10px;
    margin: 80px 0;
}

.vectorImage {
    position: relative;
    width: 50%;
    padding: 50px 0;
}

.vectorImage .mainImage {
    width: 99%;
    height: 100%;
}

.ordernowBtn {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
}

.inputLabel {
    color: #8d8d8d;
}

.inputBox {
    width: 100%;
    border: none;
    box-shadow: 0px 1px 4px 1px #dbdbdb;
    border-radius: 7px;
    padding: 4px 12px;

}

.loginButton {
    width: 100%;
    border-radius: 7px;
    margin-bottom: 10px;
}

@media (max-width: 1002px) {

    .loginCard,
    .signupCard {
        width: 95%;
    }

    .loginLogo {
        z-index: 2;
        top: 5px;
        left: 20px;
    }

    .loginLogo img {
        width: 60px;
    }

    .loginCard50 {
        width: 60%;
    }
}

@media (max-width: 600px) {
    .vectorImage {
        display: none;
    }

    .formDiv,
    .signupForm {
        width: 100%;
        border-radius: 10px;
    }

    .loginCard50 {
        width: 95%;
    }
}