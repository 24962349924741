.header-div {
    z-index: 9999;
    padding: 5px;
}

.header-div {
    /* font-family: 'helveticaneuebold'; */
    font-size: 22px;
    text-transform: uppercase;
    color: #333;
    line-height: 1.2;
}

.vh1-100 {
    height: 100vh;
}

.LoginRegisterBlock {
    width: 100%;
    float: left;
    padding: 0 80px;
}

.LoginRegisterForm {

    font-size: 13px;
    color: #333;
}

.float-left {
    float: left;
}

.LoginRegisterForm h3,
.LoginRegisterForm h2 {
    font-size: 20px;
    color: var(--primaryColor);
    /* color: #333; */
    margin: 20px 0 13px 0;
    text-align: center;
}

.btn-lg,
.btn-lg:hover {
    padding: .5rem 1rem;
    font-size: 1.15rem;
}

.user_info p {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 1.2;
}

/* ///// ======================edit profile ============================//////// */
.LoginRegisterForm .form-group label {
    font-size: 14px;

    color: #333;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}